/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { DataTableState, Dropdown } from 'everchain-uilibrary'

import { Skeleton, TextField } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'

// import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'

interface Options {
  label: string
  value: string
}
interface StrategyParams {
  strategyOperations?: IStrategyGetOperations
  onStrategiesUpdate: (strategies: any, strategyName?: any) => void
  selectedStrategy?: string
  origin?: string
}

const Strategies: React.FC<StrategyParams> = ({
  strategyOperations,
  onStrategiesUpdate,
  selectedStrategy = '',
  origin = '',
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [strategies, setStrategies] = useState<any>()
  const [strategy, setStrategy] = useState<string | undefined>(selectedStrategy)
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.name,
        value: item.id,
      })
    )
    return newOptions
  }
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    filter: undefined,
    sort: undefined,
  })

  const GetStrategies = async () => {
    setLoading(true)
    try {
      const strategiesResult = await strategyOperations?.getStrategies(
        gridState,
        undefined,
        true,
        true
      )
      const filterStrategies = strategiesResult?.data

      setStrategies(filterStrategies)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const options = getDropDownOptions(strategies || [])

  useEffect(() => {
    GetStrategies()
  }, [])

  if (loading) return <Skeleton width="330px" height={60} />
  return !options || options?.length === 0 ? (
    <></>
  ) : options && options.length > 1 ? (
    <Dropdown
      id="strategy-dropdown"
      maxwidth="300px"
      width="300px"
      options={options}
      value={strategy || undefined}
      onChange={(option?: Options) => {
        setStrategy(option?.value)
        onStrategiesUpdate(option?.value, option?.label)
      }}
      placeholder="Select a strategy"
    />
  ) : (
    <TextField
      style={{ width: '300px', maxWidth: '300px', marginBottom: '3px' }}
      data-test-id="input-strategy"
      id="input-strategy"
      disabled={true}
      fullWidth
      label="Strategy"
      name="strategy"
      variant="standard"
      value={options[0].label ?? ''}
    />
  )
}

export default Strategies
