import React from 'react'
import Accounts from 'src/presentation/pages/Accounts'
import { makeAccountsData } from '../../feature/get/Accounts'
import { makeFilesData } from '../../feature/get/Files'
import { makeBusinessGetData } from '../../feature/get/Business'
import { makeStrategyData } from '../../feature/get/Strategy'

const makeAccounts: React.FC = () => {
  return (
    <Accounts
      accountOperations={makeAccountsData()}
      businessOperations={makeBusinessGetData()}
      fileOperations={makeFilesData()}
      strategyOperations={makeStrategyData()}
    />
  )
}

export default makeAccounts
