import React, { useContext } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import { Box, Content, Grid, Header } from 'everchain-uilibrary'
import DisplayAggregates from '../../Accounts/AccountDetails/components/DisplayAggregates'
import { AccountDetailsData } from 'src/domain/models/accounts'
import { useCustomQuery } from 'src/infra/reactQuery'
import { useGetBusinessessId } from 'src/utils/user'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { PostPlacementDetailsData } from 'src/domain/models/post-placement'
import { useParams } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri } from 'src/utils/common'

interface PostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
  accountOperations: IAccountGetOperations
}

const PostPlacementDetail: React.FC<PostPlacementParams> = ({
  postPlacementOperations,
  accountOperations,
}) => {
  const { requestId, backUrl } = useParams<any>()
  const businessIds = useGetBusinessessId()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const { data: postPlacementData, isLoading: isLoadingPostPlacement } =
    useCustomQuery<PostPlacementDetailsData>(
      ['getPostPlacementDetails'],
      async () => postPlacementOperations?.getPostPlacementDetails(requestId),
      { cacheTime: 0 }
    )

  const { data: accountDetailsData, isFetching: isDetailsLoading } =
    useCustomQuery<AccountDetailsData>(
      ['getAccountDetails'],
      async () => {
        return accountOperations?.getAccountDetails(
          postPlacementData?.ecaid || '',
          businessIds
        )
      },
      { cacheTime: 0, enabled: !!postPlacementData }
    )

  const createCardTitle = () => {
    if (!accountDetailsData) return ''

    if (isInternal) return accountDetailsData?.lenderLoanID

    return `${
      accountDetailsData?.lastName
    }, ${accountDetailsData?.firstName?.charAt(0)} | ${
      accountDetailsData?.lenderLoanID
    }`
  }

  return (
    <Box>
      <Content>
        <Header
          title={createCardTitle()}
          customBackUrl={getStandardUri(backUrl)}
          style={{ padding: 20 }}
        />
        <Box data-test-id="account-details">
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12}>
              <DisplayAggregates
                account={accountDetailsData}
                isLoading={isDetailsLoading || isLoadingPostPlacement}
              />
            </Grid>
          </Grid>
        </Box>
      </Content>
    </Box>
  )
}

export default PostPlacementDetail
