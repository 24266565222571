import React, { useContext, useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import {
  Box,
  Card,
  Content,
  DataTable,
  DataTableState,
  Grid,
  Header,
  renderDate,
  Skeleton,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import {
  PostPlacementCardsData,
  PostPlacementListData,
} from 'src/domain/models/post-placement'
import { useHistory, useParams } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri } from 'src/utils/common'
import { POST_PLACEMENT } from 'src/presentation/routes'

interface PostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
}

const PostPlacementManager: React.FC<PostPlacementParams> = ({
  postPlacementOperations,
}) => {
  const { backUrl } = useParams<any>()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [group, setGroup] = useState<string>('urgent')
  const [totalRequests, setTotalRequests] = useState<number>(0)
  const [requestsData, setRequestsData] = useState<
    PostPlacementListData[] | undefined
  >()

  const history = useHistory()

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const { data: postPlacementData, isLoading: isLoadingPostPlacement } =
    useCustomQuery<PostPlacementCardsData>(
      ['getPostPlacementCards'],
      async () => postPlacementOperations?.getPostPlacementCards(),
      { cacheTime: 0 }
    )

  const { isLoading: isLoadingPostPlacementItems } = useCustomQuery<any>(
    ['getPostPlacementCardItems', group, gridState],
    async () =>
      postPlacementOperations
        ?.getPostPlacementCardItems(group, gridState)
        .then((data) => {
          setTotalRequests(data.totalCount ?? 0)
          setRequestsData(data.data)
        }),
    { cacheTime: 0 }
  )

  const getColumns = () => {
    return [
      {
        field: 'requestId',
        title: 'ID',
        show: true,
        width: 100,
        filter: 'numeric',
      },
      {
        field: 'creditor',
        title: 'Creditor',
        show: true,
        width: 180,
        notFilterable: true,
      },
      {
        field: 'lender',
        title: 'Lender',
        show: true,
        width: 180,
      },
      {
        field: 'type',
        title: 'Type',
        show: true,
        width: 180,
      },
      {
        field: 'status',
        title: 'Status',
        show: true,
        width: 180,
      },
      {
        field: 'loanId',
        title: 'Loan ID',
        show: true,
        width: 180,
      },
      {
        field: 'agency',
        title: 'Agency',
        width: 180,
        show: true,
        notFilterable: true,
      },
      {
        field: 'lastUpdatedAt',
        title: 'Last Updated',
        show: true,
        width: 150,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'assignedToCreditor',
        title: 'Assigned To',
        show: true,
        width: 130,
      },
    ]
  }

  const handleRowClick = (event: any) => {
    history.push(
      getStandardUri(`${POST_PLACEMENT}/${event.dataItem.requestId}`)
    )
  }

  return (
    <Box>
      <Content>
        <Header
          title="Post Placement Manager"
          customBackUrl={getStandardUri(backUrl)}
          style={{ paddingTop: 20, paddingBottom: 20 }}
        />
        <Box data-test-id="account-details">
          <Skeleton
            isLoading={isLoadingPostPlacement}
            width="100%"
            height="50px"
          >
            <Grid container spacing={5}>
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.urgent.total}
                  warning={
                    postPlacementData?.urgent.warning
                      ? postPlacementData?.urgent.warning
                      : ''
                  }
                  error={
                    postPlacementData?.urgent.attention
                      ? postPlacementData?.urgent.attention
                      : ''
                  }
                  success={
                    postPlacementData?.urgent.normal
                      ? postPlacementData?.urgent.normal
                      : ''
                  }
                  footer="Urgent"
                  onClick={() => setGroup('urgent')}
                  variant="primary"
                />
              </Grid>
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.info.total}
                  warning={
                    postPlacementData?.info.warning
                      ? postPlacementData?.info.warning
                      : ''
                  }
                  error={
                    postPlacementData?.info.attention
                      ? postPlacementData?.info.attention
                      : ''
                  }
                  success={
                    postPlacementData?.info.normal
                      ? postPlacementData?.info.normal
                      : ''
                  }
                  footer="Information Requests"
                  onClick={function noRefCheck() {}}
                  variant="secondary"
                />
              </Grid>
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.recallReturn.total}
                  warning={
                    postPlacementData?.recallReturn.warning
                      ? postPlacementData?.recallReturn.warning
                      : ''
                  }
                  error={
                    postPlacementData?.recallReturn.attention
                      ? postPlacementData?.recallReturn.attention
                      : ''
                  }
                  success={
                    postPlacementData?.recallReturn.normal
                      ? postPlacementData?.recallReturn.normal
                      : ''
                  }
                  footer="Recall/Return"
                  onClick={function noRefCheck() {}}
                  variant="secondary"
                />
              </Grid>
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.retention.total}
                  warning={
                    postPlacementData?.retention.warning
                      ? postPlacementData?.retention.warning
                      : ''
                  }
                  error={
                    postPlacementData?.retention.attention
                      ? postPlacementData?.retention.attention
                      : ''
                  }
                  success={
                    postPlacementData?.retention.normal
                      ? postPlacementData?.retention.normal
                      : ''
                  }
                  footer="Retention Requests"
                  onClick={function noRefCheck() {}}
                  variant="secondary"
                />
              </Grid>
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.status.total}
                  warning={
                    postPlacementData?.status.warning
                      ? postPlacementData?.status.warning
                      : ''
                  }
                  error={
                    postPlacementData?.status.attention
                      ? postPlacementData?.status.attention
                      : ''
                  }
                  success={
                    postPlacementData?.status.normal
                      ? postPlacementData?.status.normal
                      : ''
                  }
                  footer="Status Updates"
                  onClick={function noRefCheck() {}}
                  variant="secondary"
                />
              </Grid>
              {!isInternal && (
                <Grid item xl={1} sm={3}>
                  <Card
                    content={postPlacementData?.myRequests.total}
                    warning={
                      postPlacementData?.myRequests.warning
                        ? postPlacementData?.myRequests.warning
                        : ''
                    }
                    error={
                      postPlacementData?.myRequests.attention
                        ? postPlacementData?.myRequests.attention
                        : ''
                    }
                    success={
                      postPlacementData?.myRequests.normal
                        ? postPlacementData?.myRequests.normal
                        : ''
                    }
                    footer="My Requests"
                    onClick={function noRefCheck() {}}
                    variant="secondary"
                  />
                </Grid>
              )}
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.closed.total}
                  warning={
                    postPlacementData?.closed.warning
                      ? postPlacementData?.closed.warning
                      : ''
                  }
                  error={
                    postPlacementData?.closed.attention
                      ? postPlacementData?.closed.attention
                      : ''
                  }
                  success={
                    postPlacementData?.closed.normal
                      ? postPlacementData?.closed.normal
                      : ''
                  }
                  footer="Closed Accounts"
                  onClick={function noRefCheck() {}}
                  variant="secondary"
                />
              </Grid>
              <Grid item xl={1} sm={3}>
                <Card
                  content={postPlacementData?.completed.total}
                  warning={
                    postPlacementData?.completed.warning
                      ? postPlacementData?.completed.warning
                      : ''
                  }
                  error={
                    postPlacementData?.completed.attention
                      ? postPlacementData?.completed.attention
                      : ''
                  }
                  success={
                    postPlacementData?.completed.normal
                      ? postPlacementData?.completed.normal
                      : ''
                  }
                  footer="Completed"
                  onClick={function noRefCheck() {}}
                  variant="secondary"
                />
              </Grid>
            </Grid>
          </Skeleton>
        </Box>
        <Box mt={8}>
          <DataTable
            sortable={true}
            useFilterMenu={true}
            isLoading={isLoadingPostPlacementItems}
            height="100%"
            maxHeight="100%"
            gridColumns={getColumns() || []}
            gridState={gridState}
            data={requestsData}
            pageable={true}
            total={totalRequests}
            onRowClick={handleRowClick}
            onDataStateChange={(e: { dataState: any }) => {
              setGridState(e.dataState)
            }}
          />
        </Box>
      </Content>
    </Box>
  )
}

export default PostPlacementManager
