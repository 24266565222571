import React from 'react'
import { makeBusinessGetData } from '../../../feature/get/Business'
import { makePostPlacementGetData } from '../../../feature/get/PostPlacement'
import { makePostPlacementPostData } from '../../../feature/post/PostPlacement'
import PostPlacementDetail from 'src/presentation/pages/PostPlacement/PostPlacementDetail'
import { makeAccountsData } from '../../../feature/get/Accounts'

const makePostPlacement: React.FC = () => {
  return (
    <PostPlacementDetail
      businessOperations={makeBusinessGetData()}
      postPlacementOperations={makePostPlacementGetData()}
      postPlacementPostOperations={makePostPlacementPostData()}
      accountOperations={makeAccountsData()}
    />
  )
}

export default makePostPlacement
